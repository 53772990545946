import { SET_CONFIG } from '../actions';

const initialState = null;

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIG: {
      return action.payload;
    }
    default:
      return state;
  }
}
